@font-face {
  font-family: "Gilroy";
  src: local("Gilroy"), url("./fonts/Gilroy-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "Gilroy-Black";
  src: local("Gilroy-Black"), url("./fonts/Gilroy-Black.ttf") format("truetype");
}

@font-face {
  font-family: "Gilroy-Bold";
  src: local("Gilroy-Black"), url("./fonts/Gilroy-Black.ttf") format("truetype");
}

@font-face {
  font-family: "Gilroy-SemiBold";
  src: local("Gilroy-SemiBold"),
    url("./fonts/Gilroy-Semibold.ttf") format("truetype");
}
body,
html {
  font-family: "Gilroy";
  height: 100%;
  width: 100% !important;
  margin: 0;
  background-size: cover;
}

.progress {
  width: 90%;
}

@media only screen and (min-width: 600px) {
  body {
    background-image: url("./assets/desktop_background.jpeg");
  }
  .progress {
    width: 50%;
  }
}

.verticalPaddingText {
  margin-top: 15px;
  margin-bottom: 15px;
  text-align: center;
}

p,
span {
  font-family: "Gilroy";
  font-size: 10;
  margin-top: 5;
}

h1 {
  font-family: "Gilroy-Semibold";
}

h2,
h3,
h4,
h5 {
  font-family: "Gilroy-SemiBold";
  margin: 0;
  padding: 0;
}

.closedheader {
  display: flex;
  width: 100%;
  height: 100%;
  transition: 0.2s;
  position: absolute;
  y: -60;
  transform: translateY(-60px);
}

.showPassword:hover {
  cursor: pointer;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  width: 50%;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
}

.react-datepicker-wrapper,
.react-datepicker__input-container,
.react-datepicker__input-container input {
  display: block;
  width: 100%;
  font-family: "Gilroy-SemiBold";
  border-radius: 10;
  height: 100;
}
